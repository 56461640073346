<template>
    <button @click="$emit('click')" :class="['app-btn', { secondary }]" style="min-width: 100px">
        <slot />
    </button>
</template>

<script>
export default {
    props: { secondary: { type: Boolean, default: false } },
}
</script>

<style lang="scss" scoped>
.app-btn {
    border: none;
    padding: 5px 15px;
    border-radius: 4px;
    font-size: 1em;
    color: white;
    background-color: #0a52cd;
    box-shadow: none;
    &:hover {
        background-color: rgb(58, 132, 244);
    }
}
.app-btn.secondary {
    background-color: #eee;
    color: #333;
    &:hover {
        background-color: #ddd;
    }
}
</style>
