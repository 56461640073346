var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"user-list-container"},_vm._l((_vm.taggedUsers),function(user){return _c('div',{key:user._id,staticClass:"user-item"},[_c('div',[_c('span',{staticStyle:{"color":"blue"}},[_vm._v("@")]),_vm._v(_vm._s(user.firstName))])])}),0),(!_vm.viewMode)?_c('button',{staticClass:"tag-btn",attrs:{"disabled":_vm.processing},on:{"click":_vm.toggleShowUserSelection}},[_vm._v(" Tag User "),_c('div',{class:[
                'fas',
                { 'fa-plus': !_vm.showUserSelection },
                { 'fa-minus': _vm.showUserSelection },
            ]})]):_vm._e(),(!_vm.viewMode && _vm.showUserSelection)?_c('div',{staticClass:"d-flex items-center",staticStyle:{"gap":"8px"}},[(_vm.allAssignees)?_c('SelectDropdown',{directives:[{name:"show",rawName:"v-show",value:(_vm.showDropdown),expression:"showDropdown"}],attrs:{"disabled":_vm.processing,"multiple":true,"id":"user-selection","value":_vm.selectedUsers,"options":_vm.allAssignees.map((user) => ({
                    _id: user._id,
                    title: user.firstName + ' ' + user.lastName,
                }))},on:{"change":_vm.handleUserSelection}}):_vm._e(),_c('AppBtn',{directives:[{name:"show",rawName:"v-show",value:(_vm.showDropdown && !_vm.hideSave),expression:"showDropdown && !hideSave"}],staticStyle:{"padding":"2px","height":"35px","font-size":"0.8em"},attrs:{"disabled":_vm.processing},on:{"click":_vm.updateTagging}},[_vm._v(" "+_vm._s(_vm.processing ? "Please wait..." : "Add/Update")+" ")])],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }