<template>
    <div class="list mr-2 flex flex-col rounded-sm bg-backgroundLightest flex-shrink-0">
        <div class="px-3 pb-4 pt-3 uppercase text-textMedium text-13 truncate">
            {{ status.name }}
            <span class="lowercase text-13" v-if="filteredIssues.length > 0">{{
                filteredIssues.length
            }}</span>
        </div>
        <div class="h-full px-2">
            <div v-for="issue in filteredIssues" :key="issue.id">
                <div class="card mb-3">
                    <div class="card-body">
                        {{ issue.name }}
                    </div>
                </div>
            </div>
            <div class="filteredIssues" v-if="!filteredIssues.length">No Issues</div>
        </div>
    </div>
</template>

<script lang="ts">
import { Container } from "vue-smooth-dnd"
import { mapState } from "vuex"

export default {
    components: {
        Container,
    },
    computed: {
        ...mapState("project", ["activeProjectIssues"]),
        filteredIssues() {
            return this.activeProjectIssues.filter((issue) => issue.statusId._id == this.status._id)
        },
    },
    props: {
        status: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {}
    },
}
</script>

<style scoped>
.list {
    margin-right: 0.5rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    border-radius: 0.125rem;
    background-color: #f4f5f7;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: calc(100% / 4 - 8px);
    min-height: 400px;
    min-width: 200px;
}
.drop-preview {
    background-color: rgba(150, 150, 200, 0.1);
    border: 1px dashed #abc;
    margin: 5px;
}
.card-ghost {
    transition: transform 0.18s ease;
    transform: rotateZ(5deg);
}
.card-ghost-drop {
    transition: transform 0.18s ease-in-out;
    transform: rotateZ(0deg);
}
</style>
