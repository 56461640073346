<template>
    <div>
        <div class="user-list-container">
            <div class="user-item" v-for="user of taggedUsers" :key="user._id">
                <!-- <div class="close-btn">&times;</div> -->
                <div><span style="color: blue">@</span>{{ user.firstName }}</div>
            </div>
        </div>
        <button
            v-if="!viewMode"
            :disabled="processing"
            class="tag-btn"
            @click="toggleShowUserSelection"
        >
            Tag User
            <div
                :class="[
                    'fas',
                    { 'fa-plus': !showUserSelection },
                    { 'fa-minus': showUserSelection },
                ]"
            ></div>
        </button>
        <div class="d-flex items-center" v-if="!viewMode && showUserSelection" style="gap: 8px">
            <SelectDropdown
                v-show="showDropdown"
                :disabled="processing"
                v-if="allAssignees"
                :multiple="true"
                id="user-selection"
                @change="handleUserSelection"
                :value="selectedUsers"
                :options="
                    allAssignees.map((user) => ({
                        _id: user._id,
                        title: user.firstName + ' ' + user.lastName,
                    }))
                "
            />
            <AppBtn
                v-show="showDropdown && !hideSave"
                :disabled="processing"
                @click="updateTagging"
                style="padding: 2px; height: 35px; font-size: 0.8em"
            >
                {{ processing ? "Please wait..." : "Add/Update" }}
            </AppBtn>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex"
import SelectDropdown from "@/components/other/SelectDropdown"
import AppBtn from "@/components/UI/AppBtn"
import { http } from "@/utils/http"

export default {
    emits: ["change", "update"],
    computed: {
        ...mapState("project", ["allAssignees"]),
    },
    props: {
        viewMode: {
            type: Boolean,
            default: () => false,
        },
        hideSave: {
            type: Boolean,
            default: () => false,
        },
        taggedUsers: {
            type: Array,
            default: () => [],
        },
        updateRoute: {
            type: String,
            default: () => "",
        },
        createRoute: {
            type: String,
            default: () => "",
        },
    },
    data() {
        return {
            processing: false,
            selectedUsers: null,
            showUserSelection: false,
            showDropdown: false,
        }
    },
    mounted() {
        this.selectedUsers = this.taggedUsers.map((item) => item._id)
    },
    methods: {
        handleUserSelection(user) {
            console.log("change handle user selection", user)
            this.selectedUsers = user
            this.$emit("change", user)
        },
        toggleShowUserSelection() {
            this.showUserSelection = !this.showUserSelection
            setTimeout(() => {
                this.showDropdown = this.showUserSelection
            }, 700)
        },
        async updateTagging() {
            if (!this.processing) {
                this.processing = true
                try {
                    const formData = new FormData()
                    formData.append("taggedUsers", JSON.stringify(this.selectedUsers))
                    if (this.updateRoute) {
                        const res = await http.patch(this.updateRoute, formData)
                        this.$emit("update", res.data.result)
                    } else if (this.createRoute) {
                        const res = await http.post(this.createRoute, formData)
                        this.$emit("update", res.data.result)
                    }
                    this.showUserSelection = false
                } catch (err) {
                    this.$toast.error("Could not update!")
                }
            }
            this.processing = false
        },
    },
    components: {
        SelectDropdown,
        AppBtn,
    },
}
</script>

<style lang="scss" scoped>
.tag-btn {
    background-color: coral;
    font-size: 0.8em;
    padding: 5px 10px;
    border-radius: 50px;
    color: white;
    margin: 10px 0;

    &:hover {
        background-color: rgb(253, 154, 118);
    }
}
.user-list-container {
    margin: 10px 0;
    margin-top: 6px;
    gap: 5px;
    display: flex;
    flex-flow: row wrap;
}

.user-item {
    padding: 5px 15px;
    border-radius: 50px;
    font-size: 0.8em;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.5);
    position: relative;
    cursor: pointer;

    // &:hover {
    //     background-color: #eee;
    // }
}
.close-btn {
    position: absolute;
    top: -8px;
    display: flex;
    align-items: center;
    justify-content: center;
    right: -8px;
    background-color: rgb(249, 20, 20);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    text-align: center;
    color: white;
    cursor: pointer;

    &:hover {
        background-color: rgb(253, 86, 86);
    }
}
</style>
