<template>
    <router-link
        :class="['sidebar-link-item', { active }]"
        :exact-active-class="disableActiveClass ? 'sidebar-link-item-active' : ''"
        :to="link"
    >
        <i :class="`icon fas fa-${icon}`"></i>
        <div>{{ title }}</div>
    </router-link>
</template>

<script>
export default {
    props: {
        title: { type: String, default: "" },
        icon: { type: String, default: "" },
        link: { type: String, default: "#" },
        active: { type: Boolean, default: false },
        disableActiveClass: { type: Boolean, default: false },
    },
}
</script>

<style lang="scss" scoped>
.sidebar-link-item {
    padding: 10px 15px;
    text-align: left;
    align-items: center;
    display: flex;
    border-radius: 5px;
    font-size: 0.85em;
    margin-bottom: 5px;

    .icon {
        margin-right: 15px;
    }
    &:hover:not(.sidebar-link-item-active):not(.active) {
        background-color: #ddd;
        cursor: pointer;
    }
}
.sidebar-link-item-active,
.sidebar-link-item.active {
    background-color: #2b71ec;
    color: white;
}
</style>
