<template>
    <PageWrapper title="Moms" :breadCrumbs="[{ title: 'Tasks', active: true }]">
        <template #header-options> </template>
        <template>
            <div class="mt-3">
                <CrudIndex
                    v-if="!loading"
                    title="Moms"
                    :viewColumns="columns"
                    fetchUrl="/moms"
                    createUrl="/moms"
                    updateRootUrl="/moms"
                    deleteRootUrl="/moms"
                    :createFields="createFields"
                    :editingFormatter="editingFormatter"
                    @formDataUpdated="handleFormDataUpdate"
                />
            </div>
        </template>
    </PageWrapper>
</template>

<script>
import Lists from "@/components/Lists/Lists.vue"
import PageWrapper from "@/components/layout/PageWrapper.vue"
import CrudIndex from "@/components/crud/index"
import { createField, createColumn } from "@/components/crud/utils"
import { http } from "@/utils/http"

export default {
    components: { Lists, PageWrapper, CrudIndex },
    data() {
        return {
            customers: {},
            products: {},
            orders: {},
            loading: false,
            columns: [createColumn("Id", "_id", "string"), createColumn("Name", "name", "string")],
            createFields: [],
            showProjectSelection: false,
            projects: [],
            filteredProjects: [],
            companies: [],
        }
    },
    async mounted() {
        this.loadHomeData()
        this.companies = await this.fetchCompanies()
        this.projects = await this.fetchProjects()

        // const subjects = await this.fetchSubjects()
        this.recreateFields()
        this.loading = false
    },
    methods: {
        recreateFields() {
            let createFields = [
                createField("Name*", "name", "text", true),
                createField(
                    "Company*",
                    "companyId",
                    "select",
                    true,
                    "",
                    this.companies.map((item) => ({
                        _id: item._id,
                        title: item.name,
                    }))
                ),
                createField(
                    "Project*",
                    "projectId",
                    "select",
                    true,
                    "",
                    this.filteredProjects.map((item) => ({
                        _id: item._id,
                        title: item.name,
                    }))
                ),
            ]
            this.createFields = createFields
        },
        editingFormatter(item) {
            return {
                ...item,
            }
        },
        async loadHomeData() {
            try {
            } catch (err) {
                console.log("error in loading customers", err)
            }
            this.initialized = true
        },
        async fetchCompanies() {
            let result = []
            try {
                const res = await http.get("/companies")
                result = res.data.result
            } catch (err) {
                console.log("error in loading companies", err)
            }
            this.initialized = true
            return result
        },
        async fetchProjects() {
            let result = []
            try {
                const res = await http.get("/projects")
                result = res.data.result
            } catch (err) {
                console.log("error in loading projects", err)
            }
            this.initialized = true
            return result
        },
        async handleFormDataUpdate(val) {
            console.log("handle form data update", val)

            if (val) {
                if (val.companyId) {
                    // fetch projects
                    this.filteredProjects = this.projects.filter(
                        (item) => item.companyId == val.companyId
                    )
                    this.showProjectSelection = true
                    this.recreateFields()
                }
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.card-text {
    font-size: 20px;
    font-weight: 600;
}
.issue-toggle-btn {
    padding: 10px;
    margin-left: 15px;
    border-radius: 5px;
    font-size: 0.9em;
    cursor: pointer;
    background-color: #f8f8f8;
    &:hover {
        background-color: #eee;
    }
}
</style>
