<template>
    <div class="container">
        <div class="row">
            <div class="pr-6 py-8 pl-10 h-full w-full flex flex-col">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li
                            :class="['breadcrumb-item', { active: breadCrumb.active }]"
                            v-for="breadCrumb of breadCrumbs"
                            :key="breadCrumb.link"
                        >
                            <a :href="breadCrumb.link || '#'">{{ breadCrumb.title }}</a>
                        </li>
                    </ol>
                </nav>
                <header class="mt-3 d-flex justify-between text-textDarkest" v-if="title">
                    <div class="text-2xl font-medium">{{ title }}</div>
                    <div class="d-flex">
                        <slot name="header-options" />
                    </div>
                </header>
                <div class="my-3">
                    <slot />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: { breadCrumbs: { type: Array, default: [] }, title: { type: String, default: "" } },
}
</script>

<style>
.title {
    font-weight: bold;
    font-size: 1.4rem;
    color: #000;
    margin-bottom: 5px;
}
.card-body {
    flex: 1 1 auto;
    padding: 1rem 1rem;
    /* border: 2px solid #588c9dd1; */
    border-radius: 20px;
}
</style>
