<template>
    <div>
        <PageWrapper
            title="Projects"
            :breadCrumbs="[{ title: 'Welcome ' + user.firstName, active: true }]"
        >
            <template #header-options> </template>
            <template>
                <div v-if="loading">Loading...</div>
                <div class="row g-3" v-else>
                    <div
                        class="col-md-4"
                        v-if="!activeCompanyProjects || !activeCompanyProjects.length"
                    >
                        No Projects
                    </div>
                    <ListProject
                        class="col-md-4"
                        v-for="project of activeCompanyProjects"
                        :link="`/projects/${project.slug}`"
                        :key="project.id"
                        :companyData="project"
                    />
                    <div></div>
                </div>
            </template>
        </PageWrapper>
    </div>
</template>

<script>
import Lists from "@/components/Lists/Lists.vue"
import ListProject from "@/components/Lists/ListProject.vue"
import PageWrapper from "@/components/layout/PageWrapper.vue"
import AppBtn from "@/components/UI/AppBtn"
import { mapState, mapActions } from "vuex"

export default {
    components: { ListProject, Lists, PageWrapper, AppBtn },
    data() {
        return {
            customers: {},
            products: {},
            orders: {},
        }
    },
    computed: {
        ...mapState("auth", ["user"]),
        ...mapState("project", [
            "activeCompanyProjects",
            "loading",
            "activeProject",
            "activeProjectIssues",
            "issueStatuses",
        ]),
        activeStatus() {
            return (
                this.issueStatuses &&
                this.issueStatuses.find((item) => item.slug === this.$route.query.status)
            )
        },
    },
    methods: {
        ...mapActions("project", ["initIssues"]),
        filterIssues(id, slug) {
            if (id) {
                return this.activeProjectIssues.filter((issue) => issue.statusId._id === id)
            } else if (slug) {
                return this.activeProjectIssues.filter((issue) => issue.statusId.slug === slug)
            } else {
                return this.activeProjectIssues
            }
        },
    },
    async mounted() {
        await this.initIssues({ companySlug: this.$route.params.slug })
    },
    watch: {
        async $route(e, old) {
            if (old.params.slug != e.params.slug) {
                await this.initIssues({ companySlug: this.$route.params.slug })
            }
        },
    },
}
</script>

<style lang="scss" scoped></style>
