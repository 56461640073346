var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sidebar-nav",staticStyle:{"overflow":"scroll","padding-right":"0","background-color":"rgb(244, 245, 247)"}},[_c('div',{staticClass:"sidebar-blue",staticStyle:{"background-color":"#0847a6","color":"white"}},[_c('div',{staticClass:"sidebar-blue-inner"},[_c('div',{staticClass:"mt-3"}),_c('router-link',{staticClass:"mt-3 link-hover-val",attrs:{"to":'/'}},[_c('i',{staticClass:"fa fa-diamond"}),_vm._v(" "),_c('span',{staticClass:"ms-4"},[_vm._v("Home")])]),_c('a',{staticClass:"mt-3 link-hover-val",attrs:{"href":`/projects/${
                    _vm.allProjects && _vm.allProjects[0] && _vm.allProjects[0].slug
                }?search_mode=true`}},[_c('i',{staticClass:"fa fa-search"}),_vm._v(" "),_c('span',{staticClass:"ms-4"},[_vm._v("Search")])]),_c('div',[_c('router-link',{staticClass:"mt-3 link-hover-val",attrs:{"to":'/tasks/create'}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v(" "),_c('span',{staticClass:"ms-4"},[_vm._v("New Task")])])],1),_c('div',[_c('router-link',{staticClass:"mt-3 link-hover-val",attrs:{"to":'/tasks/create-bulk'}},[_c('i',{staticClass:"fa fa-plus"}),_c('span',{staticClass:"ms-4"},[_vm._v("Bulk Tasks")])])],1),_c('a',{staticClass:"mt-3 link-hover-val",attrs:{"href":`/projects/${
                    _vm.allProjects && _vm.allProjects[0] && _vm.allProjects[0].slug
                }?search_mode=true`}},[_c('i',{staticClass:"fa fa-search"}),_vm._v(" "),_c('span',{staticClass:"ms-4"},[_vm._v("Search")])])],1)]),_c('div',{staticClass:"overflow-hidden",staticStyle:{"min-height":"100vh","background-color":"#f4f5f7"}},[_c('div',{staticClass:"container mt-3"},[_vm._m(0),_c('SidebarLinkItem',{attrs:{"title":"Dashboard","icon":"wallet","link":'/dashboard'},nativeOn:{"click":function($event){return _vm.clearActiveData.apply(null, arguments)}}}),_c('div',[_c('hr'),_c('div',{staticClass:"my-3 py-2 active-project-container"},[_c('h4',{staticClass:"h6"},[_vm._v("Company")]),(_vm.companyInitialized && _vm.showSelect)?_c('SelectDropdown',{attrs:{"id":"company","additionalDelayTrigger":true,"options":_vm.companies.map((item) => ({ _id: item._id, title: item.name })),"field":"company","value":_vm.activeCompany && _vm.activeCompany._id},on:{"change":_vm.handleCompanyChange}}):_vm._e(),(!!_vm.activeCompany)?_c('h4',{staticClass:"h6 mt-2"},[_vm._v("Project")]):_vm._e(),(!!_vm.activeCompany && _vm.showSelect)?_c('div',[_c('SelectDropdown',{attrs:{"id":"project","additionalDelayTrigger":true,"options":_vm.activeCompanyProjects.map((item) => ({
                                    _id: item._id,
                                    title: item.name,
                                })),"field":"project","value":_vm.activeProject && _vm.activeProject._id},on:{"change":_vm.handleProjectChange}})],1):_vm._e()],1),(_vm.activeProject)?_c('SidebarLinkItem',{attrs:{"title":"All","icon":"database","link":"","active":_vm.isIssueStatusActive()},nativeOn:{"click":function($event){return _vm.setStatusFilter()}}}):_vm._e(),(_vm.activeProject)?_c('div',_vm._l((_vm.issueStatuses),function(issueStatus){return _c('SidebarLinkItem',{key:issueStatus._id,attrs:{"title":issueStatus.name,"icon":"database","link":"","active":_vm.isIssueStatusActive(issueStatus)},nativeOn:{"click":function($event){return _vm.setStatusFilter(issueStatus._id)}}})}),1):_vm._e()],1),(_vm.isAdmin)?_c('div',[_vm._m(1),_c('SidebarLinkItem',{attrs:{"title":"Task Status","icon":"list","link":'/task-status'}}),_c('SidebarLinkItem',{attrs:{"title":"Task Priorities","icon":"list","link":'/task-priorities'}}),_c('SidebarLinkItem',{attrs:{"title":"Departments","icon":"list","link":'/departments'}})],1):_vm._e(),_vm._m(2),(_vm.isAdmin)?_c('div',[_c('SidebarLinkItem',{attrs:{"title":"Companies","icon":"wallet","link":'/companies'}}),_c('SidebarLinkItem',{attrs:{"title":"Projects","icon":"wallet","link":'/projects'}}),_c('SidebarLinkItem',{attrs:{"title":"Moms","icon":"wallet","link":'/moms'}}),_c('SidebarLinkItem',{attrs:{"title":"Users","icon":"user","link":'/users'}}),_c('SidebarLinkItem',{attrs:{"title":"User Roles","icon":"user","link":'/user-roles'}})],1):_vm._e(),_c('SidebarLinkItem',{attrs:{"title":"Logout","icon":"sign-out","link":'#'},nativeOn:{"click":function($event){return _vm.logoutUtil.apply(null, arguments)}}})],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"my-3"},[_c('h4',{staticClass:"h6 my-2"},[_vm._v("Manage")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"my-3"},[_c('hr'),_c('h4',{staticClass:"h6 my-2"},[_vm._v("Update")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"my-3"},[_c('hr'),_c('h4',{staticClass:"h6 my-2"},[_vm._v("Settings")])])
}]

export { render, staticRenderFns }