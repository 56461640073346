<template>
    <PageWrapper title="Priority" :breadCrumbs="[{ title: 'Tasks', active: true }]">
        <template #header-options> </template>
        <template>
            <div class="mt-3">
                <CrudIndex
                    v-if="!loading"
                    title="Priority"
                    :viewColumns="columns"
                    fetchUrl="/priority"
                    createUrl="/priority"
                    updateRootUrl="/priority"
                    deleteRootUrl="/priority"
                    :createFields="createFields"
                    :editingFormatter="editingFormatter"
                />
            </div>
        </template>
    </PageWrapper>
</template>

<script>
import Lists from "@/components/Lists/Lists.vue"
import PageWrapper from "@/components/layout/PageWrapper.vue"
import CrudIndex from "@/components/crud/index"
import { createField, createColumn } from "@/components/crud/utils"

export default {
    components: { Lists, PageWrapper, CrudIndex },
    data() {
        return {
            customers: {},
            products: {},
            orders: {},
            loading: false,
            columns: [
                createColumn("Id", "_id", "string"),
                createColumn("Name", "name", "string"),
                createColumn("Value", "value", "number"),
            ],
            createFields: [],
        }
    },
    async mounted() {
        this.loadHomeData()
        // const subjects = await this.fetchSubjects()
        this.createFields = [
            createField("Name*", "name", "text", true),
            createField("Value*", "value", "number", true),
            // createField(
            //     "Subject*",
            //     "subjectId",
            //     "select",
            //     true,
            //     "",
            //     subjects.map((item) => ({
            //         _id: item._id,
            //         title: item.title,
            //     }))
            // ),
        ]
        this.loading = false
    },
    methods: {
        editingFormatter(item) {
            return {
                ...item,
            }
        },
        async loadHomeData() {
            try {
            } catch (err) {
                console.log("error in loading customers", err)
            }
            this.initialized = true
        },
    },
}
</script>

<style lang="scss" scoped>
.card-text {
    font-size: 20px;
    font-weight: 600;
}
.issue-toggle-btn {
    padding: 10px;
    margin-left: 15px;
    border-radius: 5px;
    font-size: 0.9em;
    cursor: pointer;
    background-color: #f8f8f8;
    &:hover {
        background-color: #eee;
    }
}
</style>
