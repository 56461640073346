<template>
    <tr v-if="issue" class="issue-row" @click="openTask(issue)">
        <td class="ps-1">{{ num }}.</td>
        <td style="width: 220px">
            {{ stripText(issue.name, 50) }}
        </td>
        <td>
            {{ allProjects.find((item) => item._id == issue.projectId)?.name }}
        </td>
        <td>
            {{ issue.momId && issue.momId.name }}
        </td>
        <td>
            {{
                issue.assigneeUserIds &&
                issue.assigneeUserIds
                    .map((item) => `${item.firstName} ${item.lastName && item.lastName[0]}`)
                    .join(", ")
            }}
        </td>
        <!-- <td>
            {{ issue.reporterId && issue.reporterId.firstName }}
        </td> -->
        <td>
            <div v-if="issue.statusId" :style="`color: ${issue.statusId.color || 'black'}`">
                {{ issue.statusId.name }}
            </div>
        </td>
        <td>
            <div :style="`color: ${getPriorityColor(issue.priorityId.name)}`">
                {{ issue.priorityId.name }}
            </div>
        </td>
        <!-- <td>{{ localizeDate(issue.startDate) }}</td> -->
        <td>{{ localizeDate(issue.endDate) }}</td>
        <td>{{ localizeDate(issue.interimDate) }}</td>
        <td @click.stop="deleteIssueUtil" v-if="isAdmin">
            <i v-if="!deleting" class="fas fa-trash" style="color: red"></i>
            <i v-else class="fas fa-ellipsis"></i>
        </td>
    </tr>
</template>

<script>
import { mapState, mapActions } from "vuex"
import { hasIssuePermission, localizeDate, stripText } from "@/utils/common"

export default {
    data() {
        return {
            // not useful any more
            isUserAssignedForIssue: false,
            deleting: false,
        }
    },
    props: {
        issue: { type: Object, default: () => null },
        num: { type: [String, Number], default: () => "" },
    },
    computed: {
        ...mapState("auth", ["user", "isAdmin"]),
        ...mapState("project", ["allProjects"]),
    },
    mounted() {
        this.isUserAssignedForIssue = hasIssuePermission(
            this.user._id,
            this.issue.assigneeUserIds,
            this.issue.reporterId
        )
    },
    methods: {
        ...mapActions("project", ["deleteIssue"]),
        localizeDate,
        stripText,
        openTask(issue) {
            window.open("/tasks/" + issue._id, "_blank")
        },
        async deleteIssueUtil() {
            if (this.deleting) return
            if (!confirm("Are you sure?")) return
            this.deleting = true
            try {
                await this.deleteIssue(this.issue._id)
                this.$toast.success("Issue deleted successfully")
            } catch (err) {
                console.log("error in deleting issue", err)
            }
            this.deleting = false
        },
        getPriorityColor(priority) {
            switch (priority.toLowerCase()) {
                case "high":
                case "highest":
                case "medium":
                    return "red"
                case "lowest":
                case "low":
                    return "green"
            }
        },
        getPriorityDirection(priority) {
            switch (priority.toLowerCase()) {
                case "high":
                case "highest":
                case "medium":
                    return "up"
                case "lowest":
                case "low":
                    return "down"
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.issue-card {
    background-color: white;
    padding: 10px;
    display: block;
    border-radius: 3px;
    box-shadow: 1px 1.5px 2px 0px rgba(0, 0, 0, 0.2);
    margin-bottom: 10px;
    &:hover {
        background-color: #ddd;
        cursor: pointer;
    }
}
.issue-row {
    &:hover {
        background-color: #ddd;
        cursor: pointer;
    }
}
</style>
