<template>
    <PageWrapper
        :title="activeProject && activeProject.name"
        :breadCrumbs="[{ title: activeProject && activeProject.name, active: true }]"
    >
        <template #header-options> </template>
        <template>
            <IssueFilters />
            <div v-if="activeProject && !fetchingIssues">
                <table
                    class="issue-table table table-bordered table-striped"
                    v-if="filterIssues(null, activeStatus && activeStatus.slug).length"
                >
                    <tr>
                        <th class="py-2">Sl.</th>
                        <th>Action Items</th>
                        <th>Project</th>
                        <th>Mom</th>
                        <th>Assignees</th>
                        <!-- <th>Creator</th> -->
                        <th>Status</th>
                        <th>Priority</th>
                        <!-- <th>Start Date</th> -->
                        <th>Interim Date</th>
                        <th>End Date</th>
                        <th v-if="isAdmin">Delete</th>
                    </tr>
                    <IssueRow
                        :issue="issue"
                        :key="issue._id"
                        v-for="(issue, i) of filterIssues(null, activeStatus && activeStatus.slug)"
                        :num="i + 1"
                    />
                </table>
                <div v-else>No Task Found</div>
            </div>

            <!-- <div v-if="activeProject">
                <div class="mb-3">
                    <h4>Project: {{ activeProject.name }}</h4>
                </div>
                <div v-if="activeStatus">
                    <div class="mb-3">
                        <strong>{{ activeStatus.name }}</strong>
                    </div>
                    <div class="row gx-2">
                   
                        <div
                            class="col-md-3"
                            v-for="issue of filterIssues(null, activeStatus.slug)"
                            :key="issue._id"
                        >
                            <IssueCard :issue="issue" />
                            
                        </div>
                        <div v-if="!filterIssues(null, activeStatus.slug).length">
                            No Tasks Found
                        </div>
                    </div>
                </div>
                <div class="row gx-2" v-else>
                    <div
                        class="col-md-3"
                        v-for="status of issueStatuses.filter(
                            (item) => filterIssues(item._id).length
                        )"
                        :key="status._id"
                    >
                        <div class="issue-status">
                            <div class="issue-status-info">{{ status.name }} - {{ filterIssues(status._id).length }}</div>
                            <IssueCard
                                :issue="issue"
                                :key="issue._id"
                                v-for="issue of filterIssues(status._id)"
                            />
                        </div>
                    </div>
                </div>
                <div class="my-2" v-if="!activeProjectIssues || !activeProjectIssues.length">
                    No Tasks Found
                </div>
            </div> -->
            <div v-else>Loading...</div>
        </template>
    </PageWrapper>
</template>

<script>
import { mapState, mapActions } from "vuex"
import PageWrapper from "@/components/layout/PageWrapper.vue"
import IssueFilters from "@/components/other/IssueFilters.vue"
// import IssueCard from "@/components/card/IssueCard.vue"
import IssueRow from "@/components/card/IssueRow.vue"

export default {
    computed: {
        ...mapState("auth", ["user", "isAdmin"]),
        ...mapState("project", [
            "activeProject",
            "activeProjectIssues",
            "issueStatuses",
            "fetchingIssues",
        ]),
        activeStatus() {
            return (
                this.issueStatuses &&
                this.issueStatuses.find((item) => item.slug === this.$route.query.status)
            )
        },
    },
    components: { PageWrapper, IssueRow, IssueFilters },
    methods: {
        ...mapActions("project", ["initIssues"]),
        filterIssues(id, slug) {
            if (id) {
                return this.activeProjectIssues.filter((issue) => issue.statusId._id === id)
            } else if (slug) {
                return this.activeProjectIssues.filter((issue) => issue.statusId.slug === slug)
            } else {
                return this.activeProjectIssues
            }
        },
    },
    async mounted() {
        console.log("mounted running of new project")
        await this.initIssues({ projectSlug: this.$route.params.slug })
    },
    watch: {
        async $route(e, old) {
            if (old.params.slug != e.params.slug) {
                await this.initIssues({ projectSlug: this.$route.params.slug })
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.issue-status {
    background-color: #eee;
    padding: 15px;
    height: 100%;
}
.issue-status-info {
    color: #667;
    text-transform: uppercase;
    font-size: 0.9em;
    margin-bottom: 15px;
}
.issue-table {
    font-size: 0.9em;
}
</style>
