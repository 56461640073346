<template>
    <div
        class="sidebar-nav"
        style="overflow: scroll; padding-right: 0; background-color: rgb(244, 245, 247)"
    >
        <div class="sidebar-blue" style="background-color: #0847a6; color: white">
            <div class="sidebar-blue-inner">
                <!--<div>Elpro Logo</div>-->
                <div class="mt-3"></div>
                <router-link :to="'/'" class="mt-3 link-hover-val"
                    ><i class="fa fa-diamond"></i> <span class="ms-4">Home</span></router-link
                >
                <a
                    :href="`/projects/${
                        allProjects && allProjects[0] && allProjects[0].slug
                    }?search_mode=true`"
                    class="mt-3 link-hover-val"
                    ><i class="fa fa-search"> </i> <span class="ms-4">Search</span>
                </a>
                <div>
                    <router-link :to="'/tasks/create'" class="mt-3 link-hover-val"
                        ><i class="fa fa-plus"> </i> <span class="ms-4">New Task</span></router-link
                    >
                </div>
                <div>
                    <router-link :to="'/tasks/create-bulk'" class="mt-3 link-hover-val"
                        ><i class="fa fa-plus"> </i>
                        <span class="ms-4">Bulk Tasks</span></router-link
                    >
                </div>
                <a
                    :href="`/projects/${
                        allProjects && allProjects[0] && allProjects[0].slug
                    }?search_mode=true`"
                    class="mt-3 link-hover-val"
                    ><i class="fa fa-search"> </i> <span class="ms-4">Search</span>
                </a>
            </div>
        </div>
        <div class="overflow-hidden" style="min-height: 100vh; background-color: #f4f5f7">
            <div class="container mt-3">
                <div class="my-3">
                    <h4 class="h6 my-2">Manage</h4>
                </div>

                <SidebarLinkItem
                    @click.native="clearActiveData"
                    title="Dashboard"
                    icon="wallet"
                    :link="'/dashboard'"
                />
                <!-- <div v-if="$route.name == 'project.slug'"> -->
                <div>
                    <hr />
                    <div class="my-3 py-2 active-project-container">
                        <h4 class="h6">Company</h4>
                        <SelectDropdown
                            v-if="companyInitialized && showSelect"
                            id="company"
                            :additionalDelayTrigger="true"
                            :options="
                                companies.map((item) => ({ _id: item._id, title: item.name }))
                            "
                            field="company"
                            :value="activeCompany && activeCompany._id"
                            @change="handleCompanyChange"
                        />
                        <h4 class="h6 mt-2" v-if="!!activeCompany">Project</h4>
                        <div v-if="!!activeCompany && showSelect">
                            <SelectDropdown
                                id="project"
                                :additionalDelayTrigger="true"
                                :options="
                                    activeCompanyProjects.map((item) => ({
                                        _id: item._id,
                                        title: item.name,
                                    }))
                                "
                                field="project"
                                :value="activeProject && activeProject._id"
                                @change="handleProjectChange"
                            />
                        </div>
                    </div>
                    <SidebarLinkItem
                        v-if="activeProject"
                        title="All"
                        icon="database"
                        link=""
                        :active="isIssueStatusActive()"
                        @click.native="setStatusFilter()"
                    />
                    <div v-if="activeProject">
                        <SidebarLinkItem
                            v-for="issueStatus of issueStatuses"
                            :key="issueStatus._id"
                            :title="issueStatus.name"
                            icon="database"
                            link=""
                            :active="isIssueStatusActive(issueStatus)"
                            @click.native="setStatusFilter(issueStatus._id)"
                        />
                        <!-- :link="`/projects/${activeProject.slug}?status=${issueStatus.slug}`" -->
                    </div>
                </div>
                <div v-if="isAdmin">
                    <div class="my-3">
                        <hr />
                        <h4 class="h6 my-2">Update</h4>
                    </div>
                    <SidebarLinkItem title="Task Status" icon="list" :link="'/task-status'" />
                    <SidebarLinkItem
                        title="Task Priorities"
                        icon="list"
                        :link="'/task-priorities'"
                    />
                    <SidebarLinkItem title="Departments" icon="list" :link="'/departments'" />
                </div>
                <div class="my-3">
                    <hr />
                    <h4 class="h6 my-2">Settings</h4>
                </div>
                <div v-if="isAdmin">
                    <SidebarLinkItem title="Companies" icon="wallet" :link="'/companies'" />
                    <SidebarLinkItem title="Projects" icon="wallet" :link="'/projects'" />
                    <SidebarLinkItem title="Moms" icon="wallet" :link="'/moms'" />
                    <SidebarLinkItem title="Users" icon="user" :link="'/users'" />
                    <SidebarLinkItem title="User Roles" icon="user" :link="'/user-roles'" />
                </div>
                <SidebarLinkItem
                    title="Logout"
                    icon="sign-out"
                    :link="'#'"
                    @click.native="logoutUtil"
                />
            </div>
        </div>
    </div>
</template>

<script>
import SidebarLinkGroup from "./SidebarLinkGroup.vue"
import SidebarLinkItem from "./SidebarLinkItem.vue"
import SelectDropdown from "../other/SelectDropdown.vue"
import { mapState, mapActions } from "vuex"
import { http } from "@/utils/http"
import { FILTER_TYPES } from "@/utils/common"

export default {
    computed: {
        ...mapState("auth", ["authenticated"]),
        ...mapState("auth", ["user", "isAdmin", "isSubAdmin"]),
        ...mapState("project", [
            "filterValues",
            "companyInitialized",
            "activeProject",
            "activeCompany",
            "issueStatuses",
            "activeCompanyProjects",
            "allProjects",
            "initIssues",
            "companies",
        ]),
    },
    components: { SidebarLinkGroup, SidebarLinkItem, SelectDropdown },
    data() {
        return {
            navOpen: false,
            showSelect: false,
            userData: {},
        }
    },
    mounted() {
        this.fetchUser()
        this.refreshSelect()
    },
    methods: {
        ...mapActions("auth", ["logout"]),
        ...mapActions("project", ["clearActiveProject", "clearActiveCompany", "updateFilters"]),
        async setStatusFilter(valueId) {
            await this.updateFilters({ type: FILTER_TYPES.STATUS, value: valueId ? [valueId] : [] })
        },
        isIssueStatusActive(issueStatus) {
            const options = this.filterValues.status.selectedOptions
            console.log(options, issueStatus)
            if (!issueStatus && options && options.length == 0) return true
            if (issueStatus) return options.includes(issueStatus._id)
            return false
        },
        async fetchUser() {
            try {
                const res = await http.get("/users/" + this.user._id)
                this.userData = res.data.result
            } catch (err) {
                console.log("error in fetching userData", err)
            }
        },
        async clearActiveData() {
            await this.clearActiveProject()
            await this.clearActiveCompany()
            this.refreshSelect()
        },
        refreshSelect() {
            this.showSelect = false
            setTimeout(() => {
                this.showSelect = true
            }, 100)
        },
        async handleProjectChange(projectId) {
            const foundProject = this.activeCompanyProjects.find((item) => item._id === projectId)
            if (foundProject) {
                this.$router.push(`/projects/${foundProject.slug}`)
            } else if (this.activeCompany) {
                this.$router.push(`/companies/${this.activeCompany.slug}/projects`)
                await this.clearActiveProject()
            }
        },
        async handleCompanyChange(companyId) {
            const foundCompany = this.companies.find((item) => item._id === companyId)
            if (foundCompany) {
                this.$router.push(`/companies/${foundCompany.slug}/projects`)
            } else {
                this.$router.push(`/dashboard`)
                this.clearActiveData()
            }
        },
        logoutUtil() {
            this.logout()
            window.location.href = "/login"
        },
    },
}
</script>

<style lang="scss" scoped>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
}
.sidebar-blue,
.sidebar-nav {
    font-size: 0.9em;
    height: 100%;
}
.sidebar-nav {
    width: 300px;
    left: 30px;
    z-index: 100;
    position: fixed;
}
.sidebar-blue {
    width: 55px;
    padding-left: 18px;
    position: fixed;
    left: 0;
    transition: 0.3s;
    z-index: 100;
    overflow: hidden;
    cursor: pointer;

    &:hover {
        width: 200px;
    }
}
.sidebar-blue-inner {
    width: 300px;
}
.link-hover-val {
    display: block;
    color: white;
    &:hover {
        color: #ddd;
    }
}
.active-project-container {
    background-color: white;
    padding: 5px 10px;
    border-radius: 5px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
}
</style>
