<template>
    <div>
        <PageWrapper
            title="Companies"
            :breadCrumbs="[{ title: 'Welcome ' + user.firstName, active: true }]"
        >
            <template #header-options>
                <!-- <div class="issue-toggle-btn">Only My Issues</div>
            <div class="issue-toggle-btn">Recently Updated</div> -->
            </template>
            <template>
                <div v-if="loading">Loading...</div>
                <div class="row g-3" v-else>
                    <div class="col-md-4" v-if="!companies || !companies.length">No Companies</div>
                    <ListProject
                        class="col-md-4"
                        v-for="company of companies"
                        :key="company.id"
                        :link="`/companies/${company.slug}/projects`"
                        :companyData="company"
                    />
                    <div></div>
                </div>
                <!-- <Lists /> -->
            </template>
        </PageWrapper>
    </div>
</template>

<script>
import Lists from "@/components/Lists/Lists.vue"
import ListProject from "@/components/Lists/ListProject.vue"
import PageWrapper from "@/components/layout/PageWrapper.vue"
import AppBtn from "@/components/UI/AppBtn"
import { mapState } from "vuex"

export default {
    components: { ListProject, Lists, PageWrapper, AppBtn },
    data() {
        return {
            customers: {},
            products: {},
            orders: {},
        }
    },
    mounted() {
        this.loadHomeData()
    },
    methods: {
        async loadHomeData() {
            try {
            } catch (err) {
                console.log("error in loading customers", err)
            }
            this.initialized = true
        },
    },
    computed: {
        ...mapState("project", ["companies", "loading"]),
        ...mapState("auth", ["user"]),
    },
}
</script>

<style lang="scss" scoped>
.card-text {
    font-size: 20px;
    font-weight: 600;
}
.issue-toggle-btn {
    padding: 10px;
    margin-left: 15px;
    border-radius: 5px;
    font-size: 0.9em;
    cursor: pointer;
    background-color: #f8f8f8;
    &:hover {
        background-color: #eee;
    }
}
.project-view {
    background-color: #eee;
    padding: 15px;
    color: rgb(1, 1, 1);
    border-radius: 7px;
}

.view-link {
    // margin: 10px 0px;
    // padding: 5px 25px;
    // display: inline-block;
    // border-radius: 5px;
    // background-color: white;
    // border: 1px solid #ccc;
    // &:hover {
    //     border-color: #888;
    //     background-color: rgb(224, 224, 224);
    // }
}
</style>
