<template>
    <div v-if="isUserAssignForProjects">
        <div class="project-view">
            <div>
                {{ companyData.name }}
            </div>
            <div class="mt-3">
                <router-link class="view-link" :to="link"> <AppBtn>View</AppBtn></router-link>
            </div>
        </div>
    </div>
</template>
<script>
// import { mapState } from "vuex"
import AppBtn from "@/components/UI/AppBtn"
import { mapState } from "vuex"
import { hasIssuePermission } from "@/utils/common"

export default {
    components: { AppBtn },
    computed: {
        ...mapState("auth", ["user", "isAdmin"]),
        ...mapState("project", ["activeCompany"]),
    },
    data() {
        return {
            isUserAssignForProjects: false,
        }
    },
    props: {
        link: { type: String, default: "" },
        companyData: { type: Object, default: () => null },
    },
    mounted() {
        this.isUserAssignForProjects = this.isAdmin

        if (!this.isUserAssignForProjects && (this.companyData || this.activeCompany)) {
            this.isUserAssignForProjects = hasIssuePermission(
                this.user._id,
                this.activeCompany ? this.activeCompany.userIds : this.companyData.userIds
            )
        }
    },
}
</script>
<style lang="scss" scoped>
.card-text {
    font-size: 20px;
    font-weight: 600;
}
.issue-toggle-btn {
    padding: 10px;
    margin-left: 15px;
    border-radius: 5px;
    font-size: 0.9em;
    cursor: pointer;
    background-color: #f8f8f8;
    &:hover {
        background-color: #eee;
    }
}
.project-view {
    background-color: #eee;
    padding: 15px;
    color: rgb(1, 1, 1);
    border-radius: 7px;
}

.view-link {
    // margin: 10px 0px;
    // padding: 5px 25px;
    // display: inline-block;
    // border-radius: 5px;
    // background-color: white;
    // border: 1px solid #ccc;
    // &:hover {
    //     border-color: #888;
    //     background-color: rgb(224, 224, 224);
    // }
}
</style>
