<template>
    <div>
        <div class="container">
            <h2 class="h5 my-3 text-white text-center">Minutes Management</h2>
            <div class="row">
                <div class="">
                    <div class="rounded pt-3 bg-white mt-1" style="width: 25rem; min-height: 20rem">
                        <div class="text-center">Login to your account</div>
                        <div class="card-body">
                            <form @submit.prevent="handleSubmit">
                                <div class="row mb-3 mt-3">
                                    <label for="email" class="col-md-12 col-form-label text-md-left"
                                        >Email address</label
                                    >
                                    <div class="col-md-12">
                                        <input
                                            id="email"
                                            type="email"
                                            class="form-control"
                                            v-model="email"
                                            required
                                            autocomplete="email"
                                            autofocus
                                        />
                                        <span class="invalid-feedback" role="alert">
                                            <strong>Enter</strong>
                                        </span>
                                    </div>
                                </div>

                                <div class="row mb-3 mt-4">
                                    <label
                                        for="password"
                                        class="col-md-12 col-form-label text-md-left"
                                        >Password</label
                                    >

                                    <div class="col-md-12">
                                        <input
                                            id="password"
                                            type="password"
                                            class="form-control"
                                            v-model="password"
                                            required
                                            autocomplete="current-password"
                                        />

                                        <span class="invalid-feedback" role="alert">
                                            <strong>xs</strong>
                                        </span>
                                    </div>
                                </div>

                                <div class="row mb-3"></div>

                                <div class="row mb-0">
                                    <div class="col-md-12">
                                        <button type="submit" class="login-btn">
                                            {{ processingForm ? "Please wait..." : "Login" }}
                                        </button>
                                    </div>
                                    <div class="text-center mt-3">
                                        <small
                                            ><a href="#" style="color: #0847a6"
                                                >Can't login?</a
                                            ></small
                                        >
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex"
export default {
    data() {
        return {
            email: "",
            password: "",
            processingForm: false,
        }
    },
    methods: {
        // ...mapActions("auth", ["login"]),
        ...mapActions({
            login: "auth/login",
        }),
        async handleSubmit() {
            if (this.processingForm) return

            if (!this.email || !this.password) {
                return this.$toast.error("Please Fill Details!")
            }
            this.processingForm = true
            try {
                await this.login({
                    email: this.email,
                    password: this.password,
                })
                this.$toast.info("Logged in Successfully!")
                window.location.href = "/dashboard"
            } catch (err) {
                console.log("Login err", err)
                this.$toast.error("Could Not Login!")
            }
            this.processingForm = false
        },
    },
}
</script>

<style lang="scss" scoped>
.login-btn {
    width: 100%;
    color: white;
    padding: 10px;
    border-radius: 5px;
    font-size: 0.9em;
    background-color: #0847a6;
}
</style>
